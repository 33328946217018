
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































.mb-header__nav {
  position: fixed;
  z-index: 2; // Above canvases
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  color: $c-white;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1rem);
  padding-inline: 1rem 3rem;

  @include mq(s) {
    padding-inline: 3rem;
  }

  [data-step='0'] &,
  [data-step='1'] & {
    color: $c-black;
    background-color: transparent;
  }

  [data-step='8'] &,
  [data-step='9'] &,
  [data-step='10'] & {
    @include mq(m) {
      width: calc(100vw - var(--mb-panel-width));
    }
  }
}

.mb-header__logo {
  display: none;

  @include mq(xs) {
    position: absolute;
    z-index: 2; // Display above blurred header
    top: calc(var(--mb-header-height) + var(--mb-logo-vertical-offset));
    left: 50%;
    display: block;
    height: var(--mb-logo-height);
    transform: translateX(-50%);
    transition: fill $ease-softer 250ms;
    fill: $c-white;

    [data-step='0'] &,
    [data-step='1'] & {
      display: none;
    }

    [data-step='8'] &,
    [data-step='9'] &,
    [data-step='10'] & {
      @include mq(m) {
        fill: $c-black;
        // prettier-ignore
        left: calc(
        100vw - var(--mb-panel-width) + var(--mb-panel-width) / 2 + (100vw / 12) / 2
      );
      }

      @include mq(wrapper) {
        left: calc(
          100vw - var(--mb-panel-width) + ((#{$content-width} / 12) * 9) / 2
        );
      }
    }
  }

  @include mq(m) {
    top: 3.7rem;
    transform: translate(-50%, -50%);
  }
}

.go-back {
  display: inline-flex;
  align-items: center;
  color: currentColor;
  text-decoration: none;
  transition: color $ease-softer 250ms;
}

.go-back__caret {
  margin-right: 0.4rem;
  transform: rotate(90deg);

  @include mq(s) {
    margin-left: 1rem;
  }
}

.go-back__title {
  display: none;
  font-family: $ff-alt;
  font-size: 1.4rem;
  line-height: 1.4;

  @include mq(l) {
    display: block;
    margin-right: 2rem;
  }
}

.go-back__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: var(--mb-header-height);
  padding: 0 0.976rem;
  background-color: $c-mustard;

  @include mq(m) {
    width: 11rem;
    padding: 0;
  }

  @include mq(l) {
    margin-left: 0;
  }
}

.go-back__logo__svg {
  fill: $c-white;
}

.mb-header__lang {
  margin-left: auto;
  font-size: 1.2rem;

  @include mq(m) {
    font-size: 1.5rem;
  }
}
