
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































































































































































































































































































































































































































.mb-canvases {
  display: flex;
  flex-grow: 1;
  pointer-events: none;
  margin-top: var(--mb-header-height);

  ::v-deep canvas {
    display: block;
    width: 100%;
    height: 100%;
  }

  @include mq(xs) {
    // prettier-ignore
    margin-top: calc(var(--mb-header-height) + var(--mb-logo-vertical-offset) + var(--mb-logo-height));
  }

  @include mq(m) {
    [data-step='8'] & {
      pointer-events: initial;
    }
  }
}

.mb-background {
  @include get-all-space;

  // Reduce size of background canvas on contact and overview steps
  // so that it matches the dimensions of the left side column
  [data-step='8'] &,
  [data-step='9'] &,
  [data-step='10'] & {
    @include mq($until: m) {
      bottom: unset;
      height: var(--mb-mobile-bg-canvas-height);
    }

    @include mq(m) {
      width: calc(100vw - var(--mb-panel-width));
    }
  }
}

.mb-composition {
  position: relative;
  z-index: 1;
  flex-grow: 1;

  [data-step='8'] &,
  [data-step='9'] &,
  [data-step='10'] & {
    @include mq($until: m) {
      --top: calc(var(--mb-header-height) + var(--mb-logo-height));

      position: absolute;
      top: var(--top);
      right: 0;
      left: 0;

      /* stylelint-disable indentation */
      height: calc(
        var(--mb-mobile-bg-canvas-height) - var(--top) +
          var(--mb-panel-padding-top)
      );

      @include mq(xs) {
        --top: calc(
          var(--mb-header-height) + var(--mb-logo-vertical-offset) +
            var(--mb-logo-height)
        );
      }
    }
  }

  @include mq(m) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.composition-canvas {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // Translate composition to the left on contact step so that it seats on the border between left and right column
  [data-step='8'] &,
  [data-step='9'] &,
  [data-step='10'] & {
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);

    @include mq(m) {
      top: 50%;
      left: 50%;
      max-width: 43rem;
      height: auto;
      aspect-ratio: 1;
      // translateX is eyeballed
      transform: translate(-115%, -50%);
    }

    @include mq(1100px) {
      max-width: 50rem;
      // translateX is eyeballed
      transform: translate(-110%, -50%);
    }

    @include mq(xl) {
      max-width: 60rem;
      // translateX is eyeballed
      transform: translate(-100%, -50%);
    }
  }

  @include mq(m) {
    top: 7rem;
    height: 60%;

    @media screen and (min-height: 850px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 50rem;
      height: 100%;
      aspect-ratio: 1;
    }
  }

  @include mq(xl) {
    @media screen and (min-height: 850px) {
      max-width: min(70vh, 70vw);
    }
  }

  @include mq(l) {
    top: 50%;
    right: 30%;
    left: unset;
    transform: translate(50%, -50%);
  }

  @include mq(1100px) {
    right: 35%;
  }

  @include mq(1400px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
