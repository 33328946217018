
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































































































































































































$border-radius: 2rem;
$scrollbar-width: 15px;
$col: math.div($content-width, 12);

.moods {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
  padding: calc(var(--mb-header-height) + 2rem) 3rem 0;

  @include mq(xs) {
    padding: var(--mb-header-height) 3rem 0;
  }
}

.moods__logo {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translate(-50%, -40%);
  width: $mbb-logo-width-xxs;
  margin: auto;

  @include mq(xs) {
    width: $mbb-logo-width-xs;
  }

  @include mq(l) {
    width: $mbb-logo-width;
  }
}

.moods__carousel {
  position: relative;

  ::v-deep {
    .carousel__scroller {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.moods-list {
  @extend %list-nostyle;

  padding-right: col(1, 12);
  padding-left: col(1, 12);

  ::v-deep .flickity-viewport {
    overflow: visible;
  }

  @include mq(m) {
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }

  @include mq(xl) {
    padding-right: col(2, 24);
    padding-left: col(2, 24);
  }

  @include mq(wrapper) {
    --padding: calc((100vw - #{$content-width} - #{$scrollbar-width}) / 2);

    padding-right: calc(var(--padding) + #{$col});
    padding-left: calc(var(--padding) + #{$col});
  }
}

.mood {
  &:not(:last-child) {
    margin-right: 2rem;
  }
}

.mood__title {
  @extend %fw-bold;

  position: absolute;
  bottom: 1.9rem;
  left: 1.9rem;
  color: $c-white;
  font-family: $ff-alt;
}

.mood__label,
[class*='mood__label--'] {
  position: relative;
  display: block;
  overflow: hidden;
  aspect-ratio: 280/330;
  height: 40vh;
  cursor: pointer;

  &:not([class*='--from-scratch']) {
    &::before {
      @include get-all-space;

      content: '';
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 43.92%
      );
      border-radius: $border-radius;
      pointer-events: none;
    }
  }

  &[class*='--from-scratch'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px $c-gray solid;
    border-radius: $border-radius;

    .mood__title {
      position: relative;
      bottom: unset;
      left: unset;
      margin-top: 1rem;
      padding: 0 2rem;
      color: $c-black;
      font-size: 2.5rem;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
    }
  }

  &::after {
    @include get-all-space;

    content: '';
    z-index: 1;
    border-radius: $border-radius;
    outline: 5px $c-mustard solid;
    outline-offset: -5px;
    opacity: 0;
    pointer-events: none;
  }

  @include mq(xs) {
    height: 50vh;
  }

  @include mq(s) {
    aspect-ratio: 639/403;
  }
}

.mood__label__icon {
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  margin-bottom: 1rem;
  background: $c-mustard;
  border-radius: 50%;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 5rem;
    height: 5rem;
    transform: translate(-50%, -50%);
    fill: $c-white;
  }
}

.mood__input {
  @extend %visually-hidden;

  &:checked + .mood__label,
  &:checked + [class*='mood__label--'] {
    &::after {
      opacity: 1;
    }
  }
}

.mood__picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $border-radius;
}

.moods__actions {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 1rem;

  &:hover {
    ::v-deep {
      /* stylelint-disable-next-line max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */
      .action__icon {
        transform: translateX(0);
      }
    }
  }

  [class*='action--'][class*='--no-label'] {
    display: grid;
    place-items: center;
    padding: 0.5rem;

    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    ::v-deep .action__icon {
      width: 2rem;
      height: 2rem;
    }

    @include mq(xs) {
      display: unset;
      place-items: unset;
      padding: 1rem;

      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      ::v-deep .action__icon {
        width: unset;
        height: unset;
      }
    }
  }

  @include mq(xs) {
    margin-top: 2.5rem;
  }
}
