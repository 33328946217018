
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































































































































.intro {
  margin: auto;
  padding: 2rem col(2, 24);

  @include mq(m) {
    max-width: 40rem;
    padding-right: 0;
    padding-left: 0;
  }
}

.intro__logo {
  width: $mbb-logo-width-xxs;
  margin: auto;

  @include mq(xs) {
    width: $mbb-logo-width-xs;
  }

  @include mq(l) {
    width: $mbb-logo-width;
  }
}

.intro__title {
  // prettier-ignore
  @include fluid(font-size, (xxs: 20px, xl: 35px));
}

.intro__text {
  font-size: 1.4rem;
  text-align: center;
}

.intro__picture,
[class*='intro__picture--'] {
  display: none;

  @include mq(m) {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 2rem;

    &[class*='--1'] {
      display: block;
      width: 25rem;
      height: 25rem;
      transform: translate(-220%, -90%) rotate(-20.778deg);
      filter: blur(1.5rem);
    }

    &[class*='--2'] {
      display: block;
      width: 27rem;
      height: 27rem;
      transform: translate(164%, -15%) rotate(15deg);
      filter: blur(0.25rem);
    }

    &[class*='--3'] {
      display: block;
      width: 20rem;
      height: 20rem;
      transform: translate(-315%, 107%) rotate(13deg);
      filter: blur(2rem);
    }
  }
}
