
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































$tooltip-top: -2.5rem;
$tooltip-right: -$spacing;

.form-tooltip {
  position: absolute;
  z-index: 1;
  top: $tooltip-top;
  right: $tooltip-right;
  color: $c-mustard;

  &::after {
    @include get-all-space;

    position: fixed;
    content: '';
    background: rgba($c-black, 0.8);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
  }

  &.is-open::after {
    opacity: 1;
  }

  @include mq(l) {
    z-index: 1;

    &::after {
      content: none;
    }
  }
}

.form-tooltip__button {
  @extend %button-nostyle;

  padding: $spacing $spacing $spacing $spacing * 0.5;
  color: $c-mustard;

  .label {
    @extend %visually-hidden;
  }
}

.form-tooltip__tip {
  @include center-xy;

  position: fixed;
  z-index: 10;
  display: block;
  width: 25rem;
  padding: $spacing;
  background: $c-white;
  border: 1px solid $c-light-grey;
  border-radius: 10px;
  box-shadow: $card-shadow;
  opacity: 0;
  transition: opacity 0.2s;
  visibility: hidden;
  // pointer-events: none;

  .is-open & {
    opacity: 1;
    visibility: visible;
  }

  @include mq(xs) {
    width: 30rem;
    height: fit-content;
  }

  @include mq(l) {
    position: absolute;
    top: initial;
    right: 0;
    bottom: $spacing * 2.5;
    left: initial;
    transform: translateY(5px);
    transition: opacity 0.2s, transform 0.5s ease-out;

    // Little triangle
    &::after {
      content: '';
      position: absolute;
      right: $spacing;
      bottom: -0.8rem;
      width: 1.5rem;
      height: 1.5rem;
      background: $c-white;
      border-right: 1px solid $c-light-grey;
      border-bottom: 1px solid $c-light-grey;
      transform: rotate(45deg);
    }

    .is-open &,
    .form-tooltip__button:hover + & {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}

.form-tooltip__tip__button {
  @extend %button-nostyle;

  position: absolute;
  top: -1.5rem;
  right: -1rem;
  padding: $spacing;
  color: $c-black;
  transition: color 0.2s;
  pointer-events: auto;

  &:hover {
    color: $c-mustard;
  }

  .g-label {
    color: currentColor;

    .form-tooltip:not(.side) & {
      @extend %visually-hidden;
    }
  }
}

.form-tooltip__tip__title {
  @extend %fw-bold;

  margin-bottom: $spacing * 0.5;
  color: $c-dark-grey;
}

.form-tooltip__tip__picture {
  margin: $spacing * 0.5 0;
  box-shadow: $card-shadow;
}

.form-tooltip__tip__content {
  margin-top: $spacing * 0.5;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $c-black;
}
