
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































.progress-indicator {
  @extend %fw-bold;

  // prettier-ignore
  @include fluid(font-size, (xxs: 10px, xl: 13px));

  text-align: center;
  text-transform: uppercase;
}
