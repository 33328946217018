
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















.title {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, xl: 35px));

  font-family: $ff-default;
  font-style: italic;
  line-height: 1;
  text-align: center;

  ::v-deep b {
    @extend %fw-bold;

    display: block;
    font-family: $ff-alt;
    font-style: normal;

    @include mq(l) {
      display: inline-block;
    }
  }
}
