
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.moodboard-builder {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: $dynamic-100vh;
}

.moodboard-builder__visualizer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  [data-step='8'] &,
  [data-step='9'] &,
  [data-step='10'] & {
    flex-direction: row;
  }
}

.moodboard-builder__steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.moodboard-builder__footer {
  position: sticky;
  z-index: 1;
  bottom: 0;
  display: flex;
  gap: 1rem;
  margin-top: auto;
  padding: 1rem;
  background-color: $c-white;
  border-top: 1px $c-lighter-grey solid;

  // Heavily specific selector to ensure css from Action component is overwritten
  button {
    &[class*='action--'][class*='--no-label'] {
      padding: 0 1rem;
    }

    &:last-child {
      margin-left: auto;
    }

    &[class*='action--'][class*='--btn'] {
      @include mq($until: xs) {
        padding: 0.5rem 1rem;

        /* stylelint-disable-next-line
          max-nesting-depth,
          selector-max-attribute, selector-max-combinators,
          selector-max-compound-selectors
        */
        [class*='action--'] .action__icon {
          margin-left: 0.5;
        }

        /* stylelint-disable-next-line
          max-nesting-depth,
          selector-max-attribute, selector-max-combinators,
          selector-max-compound-selectors
        */
        [class*='action--'][class*='--reverse'] .action__icon {
          margin-right: 0.5;
        }
      }
    }

    // Overly complicated selector to force specificity
    /* stylelint-disable max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */
    &[class*='--leftArrow'][class*='action--'][class*='--btn'] {
      @include mq($until: s) {
        padding: 0.5rem;

        ::v-deep .action__icon {
          margin-right: 0;
        }

        ::v-deep .action__label {
          display: none;
        }
      }
    }
    /* stylelint-enable max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */
  }

  > .wrapper {
    display: flex;
  }

  @include mq(m) {
    [data-step='8'] & {
      display: none;
    }
  }

  @include mq(l) {
    padding: 1rem 3rem;
  }
}

.moodboard-builder__progress-indicator {
  ::v-deep .progress-indicator {
    --c-active: #{$c-white};
  }
}

.moodboard-builder__tooltip-container {
  position: relative;
  margin-inline: 1rem;

  .form-tooltip__button {
    @extend %button-nostyle;

    display: block;
    height: 100%;
    padding: 0 1rem;
    color: $c-mustard;
    border: 1px $c-mustard solid;
    border-radius: 50%;

    .icon {
      display: block;
      width: 2.4rem;
      fill: $c-black;
      height: 2.4rem;
    }

    .label {
      @extend %visually-hidden;
    }

    @include mq($until: xs) {
      padding: 0.5rem;
    }
  }

  @include mq(l) {
    display: none;
  }
}

.moodboard-builder__tooltip {
  ::v-deep &.form-tooltip {
    position: relative;
    top: unset;
    right: unset;
    height: 100%;
  }

  @include mq(l) {
    display: none;
  }
}

.overview {
  width: 100%;
  padding-right: col(1, 12);
  padding-left: col(1, 12);
  text-align: center;

  @include mq(m) {
    padding-right: col(1, 8);
    padding-left: col(2, 8);
  }
}

.overview__inner {
  max-width: 51rem;
  margin-inline: auto;
}

.overview__text {
  font-family: $ff-default;
  font-style: italic;

  ::v-deep {
    b,
    strong {
      font-family: $ff-default;
      font-style: unset;
    }
  }
}

.moodboard-builder__material-picker {
  padding: 1rem;
  text-align: center;

  @include mq(l) {
    padding: 1rem 3rem;
  }
}
