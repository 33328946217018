
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































































































































































































































































































$root-inline-padding: 1.5rem;
$c-semi-transparent-bg: rgba(0, 0, 0, 0.3);
$c-border-light: rgba(255, 255, 255, 0.2);
$blur-backdrop-filter: blur(1rem);
$border-radius: 1rem;
$bp-extra-wide: 2000px;

.picker {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  color: $c-white;
  background-color: $c-semi-transparent-bg;
  border: 1px solid $c-border-light;
  border-radius: 1rem;
  backdrop-filter: $blur-backdrop-filter;

  @include mq(l) {
    display: flex;
    flex-direction: unset;
    color: unset;
    background-color: unset;
    border: unset;
    border-radius: unset;
    backdrop-filter: unset;
  }
}

.picker__content {
  padding-top: 1rem;

  @include mq(l) {
    position: fixed;
    top: calc(var(--mb-header-height) + 2.6rem);
    left: 3rem;
    width: 37rem;
    padding: 3rem 2.5rem 4rem;
    color: $c-white;
    background-color: $c-semi-transparent-bg;
    border: 1px solid $c-border-light;
    border-radius: 1rem;
    backdrop-filter: $blur-backdrop-filter;
  }

  @include mq($bp-extra-wide) {
    width: 55rem;
  }
}

.picker__title {
  margin-bottom: 1rem;
  padding-right: $root-inline-padding;
  padding-left: $root-inline-padding;

  @include mq(xs) {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  @include mq(m) {
    margin-top: 1.5rem;
  }

  @include mq($bp-extra-wide) {
    margin-bottom: 3rem;
    font-size: 4.5rem;
  }
}

.picker__description {
  display: none;

  @include mq(l) {
    display: block;
    max-width: 30rem;
    margin: 0 auto;
    padding: 0 2rem;
    font-size: 2rem;
    text-align: center;
  }

  @include mq($bp-extra-wide) {
    max-width: unset;
    font-size: 2.5rem;
  }
}

.picker__category {
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: max-content;
  height: 100%;
  padding: 0.5rem $root-inline-padding;

  @include mq(xs) {
    padding: 1rem $root-inline-padding;
  }
}

/* stylelint-disable max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */
.picker__carousel {
  display: inline-flex;
  max-width: 100%;
  border-top: 1px $c-border-light solid;

  ::v-deep {
    .carousel {
      --slide-gap: 0;

      overflow: hidden;

      .carousel__scroller {
        &::-webkit-scrollbar-thumb {
          display: none;
        }
      }
    }

    .carousel__item {
      &:not(:last-child) {
        border-right: 1px $c-border-light solid;
      }
    }

    @include mq(l) {
      .can-scroll {
        .carousel__item {
          &:first-child {
            .picker__category {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  @include mq(l) {
    overflow: hidden;
    color: $c-white;
    background-color: $c-semi-transparent-bg;
    border: 1px solid $c-border-light;
    border-radius: 1rem;
    backdrop-filter: $blur-backdrop-filter;
  }
}
/* stylelint-enable max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */

.picker__button {
  display: none;

  @include mq(l) {
    all: unset;
    padding: 2rem;
    cursor: pointer;
    transition: 0.15s $ease-softer;

    svg {
      display: block;
      fill: currentColor;
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &:active {
      background-color: #00000052;
    }
  }
}

.picker__scroller {
  display: flex;
  flex-grow: 1;
  overflow-x: auto;
}

.picker__category__title {
  @extend %fw-bold;

  margin-bottom: 0.5rem;
  font-family: $ff-alt;
  font-size: 1.3rem;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;

  @include mq(xs) {
    margin-bottom: 1.5rem;
  }

  @include mq($bp-extra-wide) {
    font-size: 1.5rem;
  }
}

.picker__list {
  @extend %list-nostyle;

  display: flex;
  gap: 0.8rem;
}

.picker__list__item {
  flex-shrink: 0;
}

.material__label {
  --radius: 0.73rem;
  --c-border: rgba(255, 255, 255, 0.5);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 5rem;
  height: 5rem;

  &.has-icon {
    --c-border: #{$c-white};
  }

  &::after {
    @include get-all-space;

    content: '';
    border-radius: var(--radius);
    outline: 1px var(--c-border) solid;
    outline-offset: -1px;
  }

  @include mq(l) {
    width: 7rem;
    height: 7rem;
  }
}

.material__input {
  @extend %visually-hidden;

  &:checked + .material__label {
    border-radius: var(--radius);
    outline: 5px $c-mustard solid;
    outline-offset: -5px;
  }
}

.material__thumbnail-container {
  width: 100%;
  height: 100%;
}

.material__thumbnail {
  position: absolute;
  z-index: -1; // Appears below label outline
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  aspect-ratio: 1;
}

.material__icon {
  display: block;
  width: 3.6rem;
  height: 3.6rem;
}
