
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































































































































































$scrollbar-width: 15px;

.contact {
  width: 100%;
  padding-right: col(1, 12);
  padding-left: col(1, 12);

  ::v-deep .form-feedback:not(.error-alert) {
    position: relative;
    margin: 0;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
  }

  @include mq(m) {
    padding-right: col(1, 8);
    padding-left: col(2, 8);
  }
}

.contact__content {
  /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
  ::v-deep .title b {
    display: inline;
  }

  @include mq(m) {
    position: relative;
    z-index: 1;
    max-width: 53rem;
    margin-inline: auto;
  }
}

.contact__content__title {
  ::v-deep em {
    // prettier-ignore
    @include fluid(font-size, (xxs: 15px, xl: 25px));

    display: block;
    margin-top: 1rem;
  }
}

.contact__content__text {
  @extend %text-center;

  margin-top: $spacing * 0.5;
  margin-bottom: $spacing;
}

.error-alert {
  position: relative;
  padding: 2rem 2rem 2.4rem;
  font-size: 1.5rem;
  border: 1px solid $c-dev-error;
  border-radius: 0.8rem;
}

.desktop-actions {
  display: none;

  @include mq(m) {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}

.desktop-actions__action {
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
}

.form-label__required {
  color: $c-mustard;
}

::v-deep .form-group {
  margin: 0;
}

.contact__input {
  position: relative;
}
