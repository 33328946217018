
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















.mb-panel {
  width: var(--mb-panel-width);
  padding-top: var(--mb-mobile-bg-canvas-height);
  background-color: $c-white;

  @include mq(m) {
    padding-top: 0;
  }
}

.mb-panel__content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: var(--mb-panel-padding-top);

  @include mq(m) {
    align-items: center;
    padding-top: var(--mb-header-height);
  }

  @include mq(wrapper) {
    width: math.div($content-width, 12) * 8;
  }
}
